.digital-literacy-container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 20px 60px;
  /* font-family: Arial, sans-serif; */
  color: #333;
}

/* .header {
  text-align: center;
  margin-bottom: 30px;
} */

header h1 {
  color: #710a85;
  margin-top: 10px;
  margin-bottom: 10px;
}

.header h2 {
  color: #710a85;
  margin-bottom: 20px;
}
/* header {
  margin-bottom: 20px;
  padding: 20px;
  color: #710a85;
} */
.header {
  display: grid;
  grid-template-columns: 13vw 1fr 13vw;
  align-items: start;
  text-align: center;
}
.header a {
  width: fit-content;
}
.header-signup-button .signInButton {
  background-color: #8668cd;
  color: white;
  margin-left: 30px;
  white-space: nowrap;
}
.table-container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  /* background-color: #f9f9f9; */
}
.d-lg-none {
  display: none;
}

/* .table-row {
  display: flex;
  padding: 10px;
  border-bottom: 1px solid rgb(193, 193, 193);
} */
.table-row {
  display: grid;
  grid-template-columns: 1.2fr 1fr 1fr 1fr 1fr;
  padding: 10px;
  border-bottom: 1px solid rgb(193, 193, 193);
}

.table-row:last-child {
  border-bottom: none;
}

.table-column {
  flex: 1;
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: center;
}

.header-row {
  background-color: #8668cd;
  color: #fff;
}

.header-column {
  text-align: center;
  font-weight: bold;
}

.header-column td {
  background: "white";
  padding: 5px;
  border-radius: 5px;
}

.package-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.package-header {
  background-color: #33b5e5;
  padding: 10px;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 100%;
}

.package-details {
  padding: 0 15px;
}

.package-details ul {
  list-style: none;
  padding: 0;
}

.package-details ul li {
  margin-bottom: 5px;
}

.subscription-column {
  text-align: center;
  font-weight: bold;
}

.footer {
  text-align: center;
  margin-top: 20px;
}

.footer p {
  color: #710a85;
  font-size: 30px;
  font-weight: bold;
}

.grid-table-container {
  display: none;
}

/* for table */

@media only screen and (max-width: 1024px) {
  .digital-literacy-container {
    padding: 20px;
  }
  header h1 {
    font-size: 20px;
  }
  header h2 {
    font-size: 20px;
  }
  .welcome-content {
    margin-top: 20px;
  }
  .heading-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 20px;
    margin-bottom: 10px;
    text-align: center;
  }
  .heading-mobile h1 {
    font-size: 20px !important;
  }
  .heading-mobile h2 {
    font-size: 18px !important;
  }
  .table-container {
    display: none;
  }
  .grid-table-container {
    overflow-x: auto;
    max-width: 100%;
    display: block;
  }

  .grid-table {
    display: grid;

    grid-template-columns: 150px 250px 250px 250px 250px;
    /* border: 1px solid #ddd; */
    width: max-content;
  }
  .grid-table-header {
    background-color: #8668cd;
    color: white;
  }

  .grid-table-header,
  .grid-table-cell {
    padding: 10px;
    border-bottom: 1px solid rgb(193, 193, 193);
    text-align: left;
  }

  /* Sticky first column */
  .sticky-column {
    position: sticky;
    left: 0;
    z-index: 2;
  }
  .top-radius {
    border-top-left-radius: 20px;
  }
  .bg-purple-cell {
    background-color: #8668cd;
  }
  .bg-green-cell {
    background-color: #95e8dc;
  }
  .footer p {
    color: #710a85;
    font-size: 24px;
  }
}
@media only screen and (max-width: 768px) {
  .header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
  header {
    margin-bottom: 0 !important;
  }
  .d-md-none {
    display: none;
  }
  .d-lg-none {
    display: block;
  }
  .grid-table {
    grid-template-columns: 150px 250px 250px 250px 250px;
  }

  .grid-table-container {
    overflow-x: scroll;
  }
  .footer p {
    color: #710a85;
    font-size: 20px;
  }
}
