.text-center {
  text-align: center;
}

.page-container {
  /* background-color: #f3e5f5;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  text-align: center; */

  /* margin: 0;
  background-image: url("../bgimg.png");
  background-size: 100% 100%;
  background-position: center; */
  /* Ensure the background image covers the entire viewport */
  /* width: 98.7vw; */
  /* width: 100vw;
  height: 100vh; */
  /* display: flex;
  align-items: center;
  justify-content: space-around; */
  /* align-items: center; */
  /* position: absolute; */
}
.image-container {
  margin: 0;
  background-image: url("../bgimg.png");
  background-size: 100% 100%;
  background-position: center;
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: -1;
}
.page-title {
  text-align: left;
  width: 40vw;
  display: block;
}
.page-title h1 {
  /* Hello! Digital Warriors */
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 54px;
  letter-spacing: 0.03em;

  color: #710a85;
}

.page-title h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 72px;
  letter-spacing: 0.03em;
  margin-top: 50px;
  color: #710a85;
}

.screen2-container {
  /* background-color: #f3e5f5; */
  /* padding: 20px; */
  /* width: 40vw;
  max-width: 600px; */
}

.screen2-container h1 {
  font-size: 24px;
  color: #673ab7;
}

.screen2-container h2 {
  font-size: 20px;
  color: #9575cd;
}

.screen2-container p {
  font-size: 16px;
  color: #7e57c2;
}

.registration-form {
}
.registration-form h3 {
  font-size: 18px;
}
.registration-form p {
  font-size: 14px;
  color: black;
  margin-top: 10px;
  margin-bottom: 40px;
}

.form-group {
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.signInButton {
  background-color: #b6eee0;
  color: #885bda;
  font-weight: bold;
  font-size: 1em;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  height: 35px;
}

.form-group label {
  /* display: block; */
  /* margin-bottom: 5px; */
  /* width: 20%; */
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  letter-spacing: 0.346133px;
  font-size: 14px;

  /* text-align: left; */
}

.form-group input {
  border: none;
  border-bottom: 2px dotted black;
  background-color: transparent;
  font-size: 14px;
  outline: none;
}

.form-group-button {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.form-next-button {
  width: 90%;
  display: flex;
  justify-content: flex-end;
}

.form-group input[type="file"] {
  padding: 0;
}

.registration-form button {
  background-color: #673ab7;
  color: white;
  /* padding: 10px 20px; */
  font-size: 1em;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  height: 35px;
}

button:hover {
  background-color: #5e35b1;
}

.signin-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-left: 120px;
  padding-right: 120px;
  padding-top: 16%;
  z-index: 1;
}
.register-link {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.modal-container {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.modal-container .modal-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-container .modal-content {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  max-width: 70vw;
  max-height: 80vh;
  padding: 30px;
  overflow-y: auto;
}

.modal-container .modal-content p {
  margin-bottom: 20px;
}

/* Media query for phones */
@media only screen and (max-width: 640px) {
  .signin-grid {
    grid-template-columns: 1fr;
    height: calc(100vh - 65px);
    padding: 40px;
    grid-row-gap: 50px;
    overflow-y: auto;
  }
  .page-title {
    width: 100%;
    margin-top: 70px;
  }
  .page-title h2 {
    font-size: 30px;
    line-height: 46px;
    margin-top: 20px;
  }
  .register-link {
    flex-direction: column;
  }
  .image-container {
    background-repeat: no-repeat;
    background-position: 0px -10px;
    background-size: 110% 105%;
  }
  .signin-header-avatar img {
    width: 50px !important;
    height: 50px !important;
    top: 10px !important;
    left: 20px !important;
  }
}

/* Media query for tabs */
@media only screen and (min-width: 641px) and (max-width: 1024px) {
  .signin-grid {
    grid-template-columns: 1fr;
    height: calc(100vh - 65px);
    /* width: calc(100% - 80px); */
    padding: 40px;
    grid-row-gap: 50px;
    overflow-y: auto;
  }
  .page-title {
    width: 100%;
    margin-top: 100px;
  }
  .page-title h2 {
    font-size: 30px;
    line-height: 46px;
    margin-top: 20px;
  }
  .register-link {
    margin-top: 30px;
    /* flex-direction: column; */
  }
  .image-container {
    /* background-position: right; */
    background-size: 139% 100%;
  }
}
