.logo {
  cursor: pointer;
  width: 76px;
  height: 76px;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.screen1-container {
  background-color: #ffffff;
  /* padding: 20px; */
  text-align: center;
  /* display: flex; */
  align-items: center; /* Align items vertically in the center */
  width: 100%; /* Set the container width to 100% of its parent */
  /* Ensure the container doesn't exceed the viewport width */
}

.screen1-container img {
  width: 100%; /* Ensure image doesn't exceed container width */
  height: 100%; /* Allow image to adjust its height proportionally */
}

.screen1-container {
  /* Remove fixed height and let it adjust based on the image */
  height: auto;
}
.max-width-container {
  max-width: 1440px;
  margin: auto;
}

.form-group-group {
  margin: 0;
  margin-bottom: 4vh;
}

label {
  display: block;
  margin-bottom: 5px;
}
.form-group-group #subject,
textarea {
  width: 73vw;
  border: 1px solid #ccc;
  border-radius: 4px;
}

textarea {
  resize: vertical;
}

/* button[type="submit"] {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #45a049;
} */

.form-group-group input {
  width: 35vw;
  text-align: center;
  height: 40px;
  border: 1px solid #ccc;
}

.content {
  height: 600px;
  margin: 50px;
  padding-top: 50px;
  border: 1px solid purple;
  border-radius: 20px;
}

.content h3 {
  font-size: 42px;
  margin-bottom: 50px;
  color: #885bda;
}

.content p {
  color: #885bda;
}

.screen1-container .youtube {
  position: absolute;
  top: 15vw;
  right: 5vw;
  height: 10px;
  display: block;
}

.screen1-container .home-hero-text {
  position: absolute;
  top: 54vw;
  left: 25vw;
  height: 10px;
  display: block;
  color: #710a85;
  font-size: 2vw;
  font-weight: bold;
}

.screen1-container iframe {
  width: 30vw;
  height: 20vw;
}

.nurture {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  justify-items: center;
  width: 100vw;
  grid-template-columns: repeat(4, 1fr);
}

.nurture div {
  width: 15vw;
  height: 15vw;
  background-color: #f3e9f5;
  border-radius: 30px;
  display: flex;
  padding: 20px;
  transition: all 0.3s;
  flex-direction: column;
  justify-content: space-around;
}

.nurture span {
  font-style: normal;
  font-weight: 600;
  font-size: 2vw;
  /* line-height: 36px; */
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.03em;

  color: #710a85;
}

.nurture div:hover {
  background-color: #b2f5ee;
}

.nurture div:hover span {
  /* margin-top: 8vw; */
  color: black;
}

.screen1-container img.home-social-icons {
  width: 60px;
  height: 60px;
}

textarea {
  font-family: Poppins;
  padding: 10px;
}

button {
  transition: all 0.3s;
}

/* new css */

.main-container-home {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #fff;
  max-width: 100vw;
  overflow-x: hidden;
}

.top-section-home {
  margin: 0;
  width: 100vw;
  height: 100%;
  background: url("../../src/assets/img/top-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.main-grid {
  position: relative;
  display: grid;
  grid-template-columns: 1.4fr 1fr;
  gap: 80px;
  width: 100%;
  max-width: 1440px;
  padding-left: 60px;
  padding-right: 60px;
  align-items: center;
  margin-bottom: 70px;
  margin-left: auto;
  margin-right: auto;
}

/* Left Grid with 2 columns and 4 rows */
.left-grid {
  display: grid;
  grid-template-rows: repeat(4, 1fr); /* 4 rows */
}
.left-grid img {
  width: 190px;
  height: 170px;
  object-fit: contain;
}

/* Alternating column sizes for each row */
.left-grid > div:nth-child(odd) {
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 1fr 3fr; /* 1fr 3fr for odd rows */
}

.left-grid > div:nth-child(even) {
  display: grid;
  grid-template-columns: 3fr 1fr;
}

/* Styling the cells in left grid */
.left-grid > div {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Right side - YouTube embed */
.right-grid {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: flex-start;
}

.right-grid iframe {
  width: 500px;
  height: 350px;
  border-radius: 10px;
}

.right-grid-text {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  color: #710a85;
}

.right-grid-text span {
  font-size: 30px;
}

.left-grid > div {
  height: 136px;
}

.hero-bottom-text {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  color: #710a85;
}

.hero-bottom-text span {
  text-decoration: underline;
}

.second-section-home {
  max-width: 1440px;
  padding-left: 60px;
  padding-right: 60px;
  margin: auto;
}

.second-section-home h3 {
  color: #710a85;
  padding-left: 100px;
  padding-right: 100px;
}
.second-section-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  z-index: 2;
}

.second-section-grid > *:nth-child(3),
.second-section-grid > *:nth-child(4) {
  padding-left: 100px;
}

.bg-primary-purple {
  background-color: #8668cd;
}

.third-section-home {
  position: relative;
  background-color: #8668cd;
  /* margin-top: 60px; */
  padding-bottom: 60px;
}

.cloud-line-top {
  position: absolute;
  top: -7vw;
  left: 0;
  margin: 0;
  z-index: 0;
  width: 100vw;
  height: 160px;
  background: url("../../src/assets/img/cloud-line-top.png");
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
}
.cloud-line-bottom {
  position: absolute;
  bottom: -5vw;
  left: 0;
  margin: 0;
  z-index: 0;
  width: 100vw;
  height: 160px;
  background: url("../../src/assets/img/cloud-line-bottom.png");
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
}

.third-section-content {
  max-width: 1440px;
  padding-left: 60px;
  padding-right: 60px;
  margin: auto;
  padding-bottom: 30px;
}

.third-section-title {
  text-align: center;
  padding-top: 70px;
  color: #b2f5ee;
  z-index: 1;
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 20px;
  position: relative; /* Added position: relative to take z-index into account */
}
.pointer-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 80px;
}
.third-section-content h3 {
  text-align: center;
  padding-top: 20px;
  font-size: 36px;
  font-weight: 300;
  color: white;
}
.third-section-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.fourth-section-home {
  padding-top: 300px;
  padding-bottom: 80px;
  margin: 0;
  width: 100vw;
  height: 100%;
  min-height: 118vh;
  background: url("../../src/assets/img/digiverse-bg.png");
  background-size: 100vw 100vw;
  background-position: 0 -60px;
  background-repeat: no-repeat;
}

.fourth-section-title {
  text-align: center;
  margin-top: -250px;
  color: #710a85;

  font-size: 54px;
  font-weight: 500;
  margin-bottom: 20px;
}
.fourth-section-heading {
  text-align: center;
  padding-top: 70px;
  color: #710a85;
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 20px;
}
.fourth-section-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 50px;
  grid-row-gap: 100px;
  padding-top: 30px;
  margin-bottom: 30px;
  max-width: 1440px;
  padding-left: 10vw;
  padding-right: 10vw;
  margin: auto;
}

.fifth-section-home {
  width: 100%;
  height: 80%;
  background: url("../../src/assets/img/fifth-bg.png");
  background-size: 100vw 100%;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 640px) {
  .logo {
    width: 40px;
    height: 40px;
  }

  .nurture div {
    width: 50px;
    height: 50px;
    border-radius: 20px;
    padding: 10px;
  }

  .nurture span {
    font-size: 8px;
    line-height: 9px;
  }
  .right-grid-text,
  .hero-bottom-text {
    font-size: 20px;
  }
  .right-grid-text span {
    font-size: 24px;
  }
  .third-section-content {
    padding-left: 30px;
    padding-right: 30px;
  }
  .third-section-content h3 {
    padding-top: 20px;
    font-size: 28px;
  }
  .contact-form {
    flex-direction: column;
    gap: 10px !important;
    padding: 10px;
  }

  .contact-form input {
    width: 100%;
  }

  .form-group-group {
    padding: 0px 10px;
  }

  .form-group-group input,
  .form-group-group textarea {
    width: 80% !important;
  }

  .screen1-container .youtube {
    /* top: 8vh; */
    right: 5vw;
  }

  .screen1-container iframe {
    /* width: 35vw; */
    height: 80px;
  }

  .screen1-container img.home-social-icons {
    width: 35px;
    height: 35px;
  }
  .screen1-container .home-social-container {
    top: -55px !important;
    gap: 15px !important;
  }

  .content {
    height: auto;
  }

  .content h3 {
    font-size: 36px;
    margin-bottom: 20px;
  }

  button.signInButton {
    font-size: 10px;
    top: -5px !important;
    margin-left: 0;
  }

  a.header-link button {
    left: 30vw !important;
  }

  a.header-signin-button button {
    height: 20px;
    right: 10px !important;
    font-size: 8px !important;
    margin-top: 7px;
  }

  a.header-signin-button.extra-margin button {
    margin-top: 12px !important;
  }

  .second-section-grid > *:nth-child(3),
  .second-section-grid > *:nth-child(4) {
    padding-left: 0px;
  }
}
