body {
  background: linear-gradient(to right, #73e4da, #b6eee0);
}
.container {
  font-style: normal;
  color: #710a85;
  text-align: center;
  font-weight: bold;
  padding: 20px;
  font-size: 1.5vw;
  margin-left: auto;
  margin-right: auto;
}
.grid {
  display: grid;
}
.grid-gap-3 {
  grid-gap: 15px;
  margin-top: 30px;
}
.grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.grid-space {
  padding-left: 60px;
  padding-right: 60px;
  grid-gap: 140px;
}

.welcome-content {
  display: grid;
  justify-content: center;
  margin-top: 30px;
}
.logout-btn {
  display: flex;
  align-items: center;
}

/* Digital Hero Zone */

header {
  margin-bottom: 20px;
}
.header-align {
  display: grid;
  grid-template-columns: 13vw 1fr 13vw;
  align-items: center;
}
.header-align a {
  width: fit-content;
}

.welcome-message {
  font-weight: bold;
}

.left-section {
  display: flex;
  flex-direction: column;
}
.right-text {
  margin-bottom: 30px;
}

.right-section p {
  font-size: 20px;
  margin-bottom: 30px;
}
.profile {
  background-color: rgb(224, 203, 224);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(100, 94, 100, 0.1);
  text-align: center;
  border: 2px groove purple;
  font-size: 1em;
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.points-gems {
  margin: 10px 0;
}

.left-section-section {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #710a85;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}

.left-section-section p {
  margin-bottom: 10px;
}
.logout-btn {
  justify-content: end;
}
.logout-btn h3 {
  white-space: nowrap;
}
.logout-btn img {
  width: 50px;
  height: 40px;
}
.heading-mobile {
  display: none;
}
.profile h2 {
  font-size: 42px;
}
.profile table {
  margin-left: 50px;
  text-align: left;
}
.leaderboard-content {
  padding: 20px;
  line-height: 40px;
  letter-spacing: 0.03em;
  margin-top: 50px;
}

/* Media query for phones */
@media only screen and (max-width: 1024px) {
  .container {
    padding: 0px;
    font-size: 2.5vw;
  }
  header {
    padding: 20px;
  }
  .header-align {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
  .left-section-section p,
  .right-section p {
    font-size: 15px;
  }
  .grid-gap-3 {
    grid-gap: 15px;
    padding-left: 85px;
    padding-right: 85px;
  }
  .grid-2 {
    grid-template-columns: 1fr;
  }
  .grid-space {
    padding-left: 20px;
    padding-right: 20px;
    grid-gap: 20px;
  }
  .logout-btn h3 {
    font-size: 14px;
  }
  .logout-btn img {
    width: 40px;
    height: 30px;
  }
  .welcome-content {
    margin-top: 20px;
  }
  .welcome-content p {
    font-size: 14px;
  }
  .header-align h1 {
    display: none;
  }
  .heading-mobile {
    display: flex;
    justify-content: center;
    font-size: 20px;
    margin-bottom: 10px;
  }
  .profile h2 {
    font-size: 20px;
  }
  .leaderboard-table th,
  .leaderboard-table td,
  .profile table th,
  .profile table td {
    font-size: 16px;
  }
  .profile table {
    margin-left: 25px;
  }
  .profile p {
    font-size: 16px;
  }
  .profile h3 {
    font-size: 18px;
  }
  .right-text {
    font-size: 14px;
  }
  .asidebtn {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px !important;
  }
  .asidebtn button {
    font-size: 12px !important;
    height: 30px !important;
    width: 100% !important;
  }
  .leaderboard-content {
    margin-top: 30px;
  }
  .leaderboard-content h3 {
    font-size: 18px;
    line-height: 130%;
  }
  .leaderboard h1 {
    font-size: 22px;
  }
  .leaderboard {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media only screen and (max-width: 640px) {
  .grid-gap-3 {
    padding-left: 20px;
    padding-right: 20px;
    grid-gap: 20px !important;
  }
}

.gem {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 0 2px;
}

.gem.green {
  background: green;
}

.gem.blue {
  background: blue;
}

.rules-link {
  cursor: pointer;
}

.status-image {
  max-width: 600px;
  max-height: 400px;
  margin: auto;
  background: none;
  padding-bottom: 40px;
}

.status-image img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.asidebtn {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  text-align: center;
  max-height: 400px;
  flex-wrap: wrap;
  gap: 40px;
}

.asidebtn button {
  background: #885bda;
  border: none;
  border-radius: 8px;
  width: 12vw;
  /* width: 48%; */
  height: 4vw;
  color: white;
  cursor: pointer;
  font-size: 1vw;
  font-weight: bold;
}

button:hover {
  background: #21a1f1;
}

.leaderboard {
  background-color: rgb(224, 203, 224);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(100, 94, 100, 0.1);
  border: 2px groove purple;
  font-size: 0.8em;

  margin-bottom: 20px;
}

.leaderboard-container {
  display: flex;
  justify-content: center; /* Center the table horizontally */
}

.leaderboard-table {
  width: 90%; /* Adjust the width as needed */
}

.leaderboard-table th,
.leaderboard-table td {
  padding: 13px;
  text-align: left;
}

.leaderboard-table th {
  /* background-color: #885bda; */
  color: rgb(0, 0, 0);
  text-align: left;
  border-radius: 4px;
}
