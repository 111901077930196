@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
}
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  letter-spacing: 0.03em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root:has(.no-doc-scroll) {
  overflow: hidden;
}
