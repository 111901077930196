.quiz-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

.iframe-container {
  text-align: center;
  background-color: #f3e9f5; /* Container background color from the image */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 80vw;
  height: 76vh;
}

/* .quiz-header {
  margin-bottom: 20px;
} */
header {
  margin-bottom: 20px;
  padding: 20px;
  color: #710a85;
}
.header-align {
  display: grid;
  grid-template-columns: 13vw 1fr 13vw;
  align-items: center;
  text-align: center;
}
.header-align a {
  width: fit-content;
}
.welcome-content {
  margin-top: 0;
}
.welcome-content p {
  font-size: 14px;
}

.highlight {
  color: #ff5e5e; /* Highlight color for 'Password' */
}

.user-info {
  margin-top: 10px;
  color: #666;
}

.quiz-container {
  /* background-color: #e9e3ff; Light purple color for quiz interface */
  /* padding: 40px; */
  border-radius: 10px;
  /* margin-bottom: 20px; */
}

.quiz-interface {
  font-size: 24px;
  color: #333;
}

.quiz-footer {
  font-size: 18px;
  color: #666;
  text-align: center;
}

.quiz-footer .points {
  font-size: 24px;
  color: #ff5e5e; /* Points color */
}

.quiz-interface iframe {
  width: 100%;
  height: 70dvh;
}
.heading-mobile {
  display: none;
}

@media only screen and (max-width: 1024px) {
  header {
    padding: 20px;
  }
  .header-align {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
  .welcome-content {
    margin-top: 20px;
  }
  .logout-btn h3 {
    font-size: 14px;
  }
  .logout-btn img {
    width: 40px;
    height: 30px;
  }
  .header-align h1 {
    display: none;
  }
  .heading-mobile {
    display: flex;
    justify-content: center;
    font-size: 20px !important;
    margin-bottom: 10px;
  }
}
