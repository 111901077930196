.custom-card {
  background-color: white;
  border: 2px solid #b887c1;
  border-radius: 12px;
  color: #710a85;
  font-size: 20px;
  font-weight: 300;
  width: 100%;
  padding: 10px;
  max-width: 440px;
  text-align: center;
}

/* cloud */

.cloud {
  max-width: 440px;
  min-height: 220px;
  display: flex;
  padding-top: 40px;
  justify-content: center;
  align-items: center;

  background: url("../../src/assets/img/cloud.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.cloud-text {
  position: relative;
  padding: 20px 40px;
  color: #710a85;
  text-align: center;
  z-index: 2;
}

/* square-card */

.square-card {
  background-color: #f3e9f5;
  height: 260px;
  max-width: 300px;
  border-radius: 12px;
  color: #710a85;
  font-size: 24px;
  font-weight: 600;
  padding: 20px;
  display: grid;
  align-items: center;
  text-align: center;
  font-weight: 500;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease; /* Smooth transition for background and scale */
}

.square-card:hover {
  background-color: #b2f5ee;
  transform: scale(1.05); /* Slight zoom effect on hover */
}

.square-card h4 {
  transition:
    padding-top 0.3s ease,
    color 0.3s ease; /* Smooth transition for padding and color */
}

.square-card:hover h4 {
  padding-top: 60px;
  color: #710a85; /* Color change on hover (if desired) */
}

.literacy-card {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  grid-gap: 10px;
  align-items: center;
}
.literacy-card h3 {
  font-weight: 300;
  font-size: 20px;
  margin-bottom: 16px;
  max-width: 240px;
  color: #710a85;
}
.literacy-card p {
  font-weight: 300;
  font-size: 14px;
  max-width: 240px;
}
.read-more {
  display: flex;
  align-items: center;
  grid-gap: 10px;
}
.read-more h4 {
  text-decoration: underline;
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 400;
}

.read-more img {
  height: 14px;
  width: 18px;
}

/* pointer */
.pointer {
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-gap: 20px;
  align-items: baseline;
}
.pointer img {
  height: 18px;
  width: 18px;
  object-fit: contain;
}
.pointer-icon {
  background-color: #b2f5ee;
  height: 20px;
  width: 20px;
  padding: 3px;
  border-radius: 4px;
}
.pointer h3 {
  font-size: 24px;
  font-weight: 300;
  color: #b2f5ee;
  text-align: left;
}
